//
//
//
//
import Brand from '@/assets/images/logo/logo.svg';
export default {
  name: 'TelosLogo',
  components: {
    Brand: Brand
  }
};